<template>
  <div>
    <v-row class="mb-2">
      <v-col>
        <h3>
          ລວມຕຸກນ້ຳທັງໝົດ
          <span class="primary-color">({{ countTotal }})</span>
        </h3>
      </v-col>
      <v-col>
        <h3>
          ຕຸກເປົ່າ
          <span class="primary-color">({{ countEmpty }})</span>
        </h3>
      </v-col>
      <v-col>
        <h3>
          ຕຸກມີນ້ຳ
          <span class="primary-color">({{ countUsing }})</span>
        </h3>
      </v-col>
      <v-col>
        <h3>
          ຕຸກໝົດອາຍຸ
          <span class="primary-color">({{ countExpire }})</span>
        </h3>
      </v-col>
      <v-col>
        <h3>
          ຕຸກປິດໃຊ້ງານ
          <span class="primary-color">({{ countClose }})</span>
        </h3>
      </v-col>
      <v-col class="text-right">
        <v-btn
            color="primary"
            @click="exportData()"
            :loading="exportLoading"
            :disabled="exportLoading"
        >Export
        </v-btn
        >
      </v-col
      >
    </v-row>
    <v-data-table
        :headers="headers"
        :items="bottleFactory"
        :disable-pagination="true"
        hide-default-footer
        class="elevation-1"
        :loading="TableLoading"
        :disabled="TableLoading"
        loading-text="Loading... Please wait"
        :search="searchBottleFactory"
    >
      <!--Action -->
      <template v-slot:item.date="{ item }">
        {{ moment(item.created_at).format("HH:MM DD-MM-YYYY") }}
        <!-- {{item.created_at}} -->
      </template>
      <template v-slot:item.updated_at="{ item }">
        {{ moment(item.updated_at).format("HH:MM DD-MM-YYYY") }}
        <!-- {{item.created_at}} -->
      </template>
      <template v-slot:item.round="{ item }">
        <v-chip :color="getRoundColor(item.round)">{{ item.round }}</v-chip>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip :color="getColor(item.status)">{{
            getStatus(item.status)
          }}
        </v-chip>
        <!-- <v-chip v-if="item.status === 'using'" class="success">{{item.status}}</v-chip>
     <v-chip v-else-if="item.status === 'empty'" class="danger">{{item.status}}</v-chip> -->
      </template>
      <!-- <template v-slot:item.status_type="{item}">
        <v-chip :color="getStatusTypeColor(item.status_type)">
                  {{getStatusType(item.status_type)}}
        </v-chip>
     </template> -->
<!--      <template v-slot:item.actions="{ item }">-->
<!--        &lt;!&ndash; <v-icon small class="mr-2" @click="OpenModalEdit(item)">-->
<!--          mdi-pencil-->
<!--        </v-icon> &ndash;&gt;-->
<!--        <v-icon small @click="viewProduct(item.id)">mdi-eye</v-icon>-->
<!--      </template>-->
    </v-data-table>
    <br/>
    <template>
      <Pagination
          v-if="pagination.last_page > 1"
          :pagination="pagination"
          :offset="offset"
          @paginate="fetchBottleFactory()"
      ></Pagination>
    </template>
    <v-dialog
        v-model="viewFactory"
        persistent
        width="800px"
        max-with="1200px"
        loading
    >
      <template @close="close">
        <v-card :loading="loading" :disabled="loading">
          <v-card-title>
            <h4>ຕິດຕາມກຕຸກນ້ຳ</h4>
          </v-card-title>
          <v-card-text>
            <v-timeline v-if="tracking.length > 0" dense fill-dot>
              <v-timeline-item
                  v-for="(track, i) in tracking"
                  v-bind:value="track.id"
                  :key="i"
                  class="mb-4"
                  color="#00c1d2"
                  icon="mdi-arrow-up-circle"
                  icon-color="primary-color lighten-2"
                  large
              >
                <v-row justify="space-between">
                  <v-col cols="9">
                    <h2 class="font-weight-bold">
                      {{ getTrackStatusType(track.status_type) }}
                      {{ getTrackStoreType(track.store_type) }}
                    </h2>
                    <span v-if="track.driver"
                    >ທະບຽນລົດ: {{ track.driver.license_plate }}</span
                    >
                    <span v-if="track.branch">
                      ສາຂາ: {{ track.branch.name }}</span
                    >
                    <span v-if="track.customer">
                      ລູກຄ້າ: {{ track.customer.name }} / ໂທ:
                      {{ track.customer.phone }}</span
                    >
                    <p class="green--text">{{ track.status }}.</p>
                  </v-col>
                  <v-col class="text-left" cols="3">
                    <span>
                      {{
                        moment(track.created_at).format("HH:MM / DD-MM-YYYY")
                      }}</span
                    >
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
            <h2 v-else class="text-center">"ຕຸກນ້ຳຍັງຢູ່ໃນໂຮງງານ"</h2>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1 depressed"
                  text
                  @click="viewFactory = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>
<script>
import Pagination from "@/plugins/pagination/pagination";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      exportLoading: false,
      bottleFactory: [],
      countFactoryBottle: 0,
      searchBottleFactory: "",
      countTotal: 0,
      countEmpty: 0,
      countUsing: 0,
      countExpire: 0,
      countClose: 0,
      //Pagination
      offset: 15,
      pagination: {},
      per_page: 200,
      headers: [
        {
          text: "ບາໂຄດ",
          align: "start",
          value: "code_id",
          sortable: false,
        },
        {
          text: "ຕຸກນ້ຳ",
          align: "center",
          value: "name",
        },
        {
          text: "ຈຳນວນຄັ້ງໃນການໃຊ້",
          align: "center",
          value: "round",
        },
        {text: "ສະຖານະຕຸກ", value: "status", sortable: false},
        {
          text: "ວັນທີ່ສ້າງ",
          align: "start",
          value: "date",
        },
        {
          text: "ວັນທີ່ອັບເດດ",
          align: "start",
          value: "updated_at",
        },
        // { text: "Actions", value: "actions", sortable: false },
      ],
      loading: false,
      TableLoading: false,
      viewFactory: false,
      tracking: [],
      barcode: "",
      branch: {},
      customer: {},
      driver: {},
      products: [],
    };
  },
  metaInfo() {
    return {
      title: `ຂໍ້ມູນຕຸກນ້ຳຢູ່ໂຮງງານ`,
    }
  },
  methods: {
    //Bottle Have water
    fetchBottleFactory() {
      this.TableLoading = true;
      this.$axios
          .get("factory/list-products/factory", {
            params: {
              page: this.pagination.current_page,
              per_page: this.per_page,
              search: this.searchBottleFactory,
            },
          })
          .then((res) => {
            this.bottleFactory = res.data.products.data;
            this.countFactoryBottle = res.data.products.total;
            this.countTotal = res.data.countTotal,
                this.countEmpty = res.data.countEmpty,
                this.countUsing = res.data.countUsing,
                this.countExpire = res.data.countExpire,
                this.countClose = res.data.countClose,
                this.pagination = res.data.products;
            this.TableLoading = false;
          })
          .catch(() => {
            this.TableLoading = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
    },
    getRoundColor(round) {
      if (round >= 20) return "error";
      else return "success";
    },
    getColor(status) {
      if (status == "using") return "success";
      else if (status == "empty") return "info";
      else if (status == "expire") return "error";
      else if (status == "close") return "warning";
      else return;
    },
    getStatus(status) {
      if (status == "using") return "ມີນ້ຳ";
      else if (status == "empty") return "ບໍ່ມີນ້ຳ";
      else if (status == "expire") return "ໝົດອາຍຸ";
      else if (status == "close") return "ປິດໃຊ້ງານ";
      else return;
    },

    exportData() {
      this.exportLoading = true;
      this.$axios
          .get(
              "export-products/factory", {responseType: "blob"}
          )
          .then((res) => {
            setTimeout(() => {
              const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
              const fileLink = document.createElement("a");
              fileLink.href = fileUrl;
              fileLink.setAttribute(
                  "download",
                  "Bottle_In_Factory" +
                  ".xlsx"
              );
              document.body.appendChild(fileLink);
              fileLink.click();
              document.body.removeChild(fileLink);
              this.exportLoading = false;
            }, 100);
          })
          .catch(() => {
            this.exportLoading = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
    },
  },

  created() {
    this.fetchBottleFactory();
  },
};
</script>
<style>
.primary-color {
  color: #00c1d2;
}
</style>
