<template>
  <div>
    <v-row class="my-2">
      <v-col>
        <h3>
          ລົດຂົນສົ່ງ
          <span class="primary-color">({{ totalTruck }})</span>
        </h3>
      </v-col>
      <v-col>
        <h3>
          ລວມຕຸກມີນ້ຳ
          <span class="primary-color">({{ sumBottleTruck }})</span>
        </h3>
      </v-col>
      <v-col>
        <h3>
          ລວມຕຸກເປົ່າ
          <span class="primary-color">({{ sumBottleEmptyTruck }})</span>
        </h3>
      </v-col>
      <!-- <v-col>
        <v-select
          :items="['All', 'Hongkhar', 'Phonthong']"
          label="ລົດຂົນສົ່ງ"
          dense
          outlined
        ></v-select>
      </v-col> -->
      <v-col class="text-right">
        <v-btn
            color="primary"
            @click="exportData()"
            :loading="exportLoading"
            :disabled="exportLoading"
        >Export
        </v-btn
        >
      </v-col
      >
    </v-row>
    <v-data-table
      :headers="headers"
      :items="trucks"
      :disable-pagination="true"
      hide-default-footer
      class="elevation-1"
      :loading="TableLoading"
      :disabled="TableLoading"
      loading-text="Loading... Please wait"
    >
      <!--Action -->
      <!-- <template v-slot:item.created_at="{ item }">
        {{ moment(item.created_at).format("HH:MM DD-MM-YYYY") }}
         {{item.created_at}} 
      </template>-->
      <template v-slot:item.status="{ item }">
        <v-chip :color="getColor(item.status)">{{
          getStatus(item.status)
        }}</v-chip>
      </template>
      <template v-slot:item.amount="{ item }">
        <v-chip color="success"
          ><v-icon small @click="viewProduct(item.id)"
            >{{ item.products.length }}
          </v-icon></v-chip
        >
      </template>
      <template v-slot:item.bottleEmpty="{ item }">
        <v-chip color="error"
          ><v-icon small @click="viewEmptyBottleDriver(item.id)"
            >{{ item.emptyBottles.length }}
          </v-icon></v-chip
        >
      </template>
    </v-data-table>
    <br />
    <template>
      <Pagination
        v-if="pagination.last_page > 1"
        :pagination="pagination"
        :offset="offset"
        @paginate="fetDataTruck()"
      ></Pagination>
    </template>

    <!-- View Bottle Have water-->
    <div>
      <v-dialog
        v-model="viewDriver"
        persistent
        width="800px"
        max-with="1200px"
        loading
        v-if="sumTotalBottleInTruck > 0"
      >
        <template @close="close">
          <v-card :loading="loading" :disabled="loading">
            <v-card-title>
              <h4>ຕຸກມີນ້ຳໃນລົດຂົນສົ່ງ ({{ sumTotalBottleInTruck }})</h4>
            </v-card-title>
            <v-card-text>
              <v-simple-table
                v-for="(items, index) in listProductDetail"
                :key="index"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">ບາໂຄດ</th>
                      <th class="text-left">ວັນທີນຳເຂົ້າ</th>
                      <th class="text-left">ວັນທີສົ່ງອອກ</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in items.products" :key="item.id">
                      <td>{{ item.barcode }}</td>
                      <td>{{ item.import_date }}</td>
                      <td>{{ item.export_date }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1 depressed"
                  text
                  @click="viewDriver = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
    </div>

    <!-- View Bottle  Empty water-->
    <div>
      <v-dialog
        v-model="viewDriverEmpty"
        persistent
        width="800px"
        max-with="1200px"
        loading
        v-if="sumTotalEmptyBottleInTruck > 0"
      >
        <template @close="close">
          <v-card :loading="loading" :disabled="loading">
            <v-card-title>
              <h4>ຕຸກເປົ່າໃນລົດຂົນສົ່ງ ({{ sumTotalEmptyBottleInTruck }})</h4>
            </v-card-title>
            <v-card-text>
              <v-simple-table
                v-for="(items, index) in listProductDetail"
                :key="index"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">ບາໂຄດ</th>
                      <th class="text-left">ວັນທີ່ຮັບເຂົ້າ</th>
                      <!-- <th class="text-left">Update date</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in items.emptyBottles" :key="item.id">
                      <td>{{ item.barcode }}</td>
                      <td>  {{ moment(item.created_at).format("HH:MM DD-MM-YYYY") }}</td>
                      <!-- <td>  {{ moment(item.updated_at).format("HH:MM DD-MM-YYYY") }}</td> -->
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1 depressed"
                  text
                  @click="viewDriverEmpty = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Pagination from "@/plugins/pagination/pagination";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      type: "factory",
      exportLoading:false,
      headers: [
        {
          text: "ລະຫັດລົດ",
          align: "start",
          value: "truck_number",
          sortable: false,
        },
        {
          text: "ທະບຽນລົດ",
          align: "start",
          value: "license_plate",
          sortable: false,
        },
        {
          text: "ເບີໂທ",
          align: "start",
          value: "phone",
          sortable: false,
        },
        // {
        //   text: "Updated",
        //   align: "start",
        //   value: "created_at",
        // },
        { text: "ຕຸກມີນ້ຳ", align: "center", value: "amount" },
        { text: "ຕຸກເປົ່າ", value: "bottleEmpty", align: "center" },
      ],
      loading: false,
      TableLoading: false,
      viewDriver: false,
      viewDriverEmpty: false,
      tracking: [],
      barcode: "",
      branch: {},
      customer: {},
      driver: {},
      driverProducts: [],
      products: [],
      listProductDetail: [],
      trucks: [],
      //Pagination
      offset: 12,
      pagination: {},
      per_page: 200,
      totalTruck: 0,
      sumBottleTruck: 0,
      sumBottleEmptyTruck: 0,
      sumTotalBottleInTruck: 0,
      sumTotalEmptyBottleInTruck: 0,
    };
  },
  metaInfo() {
    return {
      title: `ຂໍ້ມູນຕຸກນ້ຳຢູ່ລົດຂົນສົ່ງ`,
      meta: [
        { name: 'description', content: 'ຂໍ້ມູນຕຸກນ້ຳຢູ່ລົດຂົນສົ່ງ'},
      ]
    }
  },
  methods: {
    fetDataTruck() {
      this.TableLoading = true;
      this.$admin
        .get("report/product/truck/all", {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
          },
        })
        .then((res) => {
          this.trucks = res.data.listProducts.data;
          this.pagination = res.data.listProducts;
          this.totalTruck = res.data.listProducts.total;
          this.sumBottleTruck = res.data.sumTotalBottle;
          this.sumBottleEmptyTruck = res.data.sumTotalEmptyBottle;
          this.TableLoading = false;
        })
        .catch(() => {
          this.TableLoading = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },
    viewProduct(id) {
      this.viewDriver = true;
      this.loading = true;
      this.$admin
        .get("report/product/truck/" + id)
        .then((res) => {
          this.listProductDetail = res.data.listProducts.data;
          this.sumTotalBottleInTruck = res.data.sumTotalBottle;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          // this.viewDriver = false;
        });
    },
   async viewEmptyBottleDriver(id) {
      try {
        this.loading = true;
        this.viewDriverEmpty = true;
        await this.$admin
            .get("report/product/truck/" + id)
            .then((res) => {
              this.listProductDetail = res.data.listProducts.data;
              this.sumTotalEmptyBottleInTruck = res.data.sumTotalEmptyBottle;
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
      } catch (e) {
        this.loading = false;
      }
    },

    getColor(status) {
      if (status == "using") return "success";
      else if (status == "empty") return "error";
      else return "green";
    },
    getStatus(status) {
      if (status == "using") return "ມີນ້ຳ";
      else if (status == "empty") return "ບໍ່ມີນ້ຳ";
      else return;
    },
    getStatusType(status_type) {
      if (status_type == "inbound_factory") return "ຢູ່ໂຮງງານດຫັຫດຫັ";
      else if (status_type == "outbound_factory") return "ອອກຈາກໂຮງງານ";
      else if (status_type == "inbound_branch") return "ຢູ່ຂາຂາ";
      else if (status_type == "outbound_branch") return "ອອກຈາກສາຂາ";
      else if (status_type == "customer") return "ຢູ່ນຳລູກຄ້າ";
      else if (status_type == "coming_to_factory") return "ກຳລັງກັບສູ່ໂຮງງານ";
      else return status_type;
    },
    getStatusTypeColor(status) {
      if (status == "inbound_factory") return "warning";
      else if (status == "outbound_factory") return "success";
      else if (status == "inbound_branch") return "info";
      else if (status == "outbound_branch") return "success";
      else if (status == "customer") return "primary";
      else if (status == "coming_to_factory") return "error";
      else return;
    },

    getTrackStatusType(status) {
      if (status == "inbound") return "ເຂົ້າ";
      else if (status == "outbound") return "ອອກຈາກ";
      else return;
    },
    getTrackStoreType(status) {
      if (status == "factory") return "ໂຮງງານ";
      else if (status == "truck") return "ລົດຂົນສົ່ງ";
      else if (status == "branch") return "ສາຂາ";
      else if (status == "customer") return "ລູກຄ້າ";
      else return;
    },
    exportData() {
      this.exportLoading = true;
      this.$admin
          .get(
              "export/product/driver", {responseType: "blob"}
          )
          .then((res) => {
            setTimeout(() => {
              const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
              const fileLink = document.createElement("a");
              fileLink.href = fileUrl;
              fileLink.setAttribute(
                  "download",
                  "ຕຸກນ້ຳຢູ່ລົສຂົນສົ່ງ" +
                  ".xlsx"
              );
              document.body.appendChild(fileLink);
              fileLink.click();
              document.body.removeChild(fileLink);
              this.exportLoading = false;
            }, 100);
          })
          .catch(() => {
            this.exportLoading = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
    },
  },
  created() {
    this.fetDataTruck();
  },
};
</script>
<style>
.primary-color {
  color: #00c1d2;
}
</style>
