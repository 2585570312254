<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          ຈຳນວນຕຸກນ້ຳ
          <span class="primary-color">({{ countEmptyBottle }})</span>
        </h2>
      </v-col>
      <v-col>
        <v-text-field
          outlined
          dense
          clearable
          label="ຄົ້ນຫາ ດ້ວຍລະຫັດຕຸກນ້ຳ"
          type="text"
          v-model="searchEmptyWater"
          @keyup.enter="fetchDataEmptyWaterBottle()"
        >
        </v-text-field>
      </v-col>
      <v-col class="text-right">
        <v-btn
            color="primary"
            @click="exportData()"
            :loading="exportLoading"
            :disabled="exportLoading"
        >Export
        </v-btn
        >
      </v-col
      >
    </v-row>

    <v-data-table
      :headers="headersEmptyBottle"
      :items="emptyWaterBottle"
      :disable-pagination="true"
      hide-default-footer
      class="elevation-1"
      :loading="TableLoading"
      :disabled="TableLoading"
      loading-text="Loading... Please wait"
      :search="searchEmptyWater"
    >
      <template v-slot:item.round="{ item }">
        <v-chip :color="getRoundColor(item.round)">{{ item.round }}</v-chip>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ moment(item.created_at).format("MM:HH DD-MM-YYYY") }}
        <!-- {{item.created_at}} -->
      </template>
      <template v-slot:item.updated_at="{ item }">
        {{ moment(item.updated_at).format("MM:HH DD-MM-YYYY") }}
        <!-- {{item.created_at}} -->
      </template>
      <template v-slot:item.status_type="{ item }">
        <v-chip :color="getStatusTypeColor(item.status_type)">
          {{ getStatusType(item.status_type) }}
        </v-chip>
      </template>
    </v-data-table>
    <br />
    <template>
      <Pagination
        v-if="pagination.last_page > 1"
        :pagination="pagination"
        :offset="offset"
        @paginate="fetchDataEmptyWaterBottle()"
      ></Pagination>
    </template>
  </div>
</template>
<script>
// import manage_water_bottle from "@/mixins/water/manage_water_bottle";
import Pagination from "@/plugins/pagination/pagination";
export default {
  // mixins: [manage_water_bottle],
  components: {
    Pagination,
  },
  data() {
    return {
      TableLoading: false,
      exportLoading:false,
      emptyWaterBottle: [],
      searchEmptyWater: "",
      //Pagination
      countEmptyBottle: 0,
      offset: 15,
      pagination: {},
      per_page: 200,
      search: "",
      headersEmptyBottle: [
        {
          text: "Barcode",
          align: "start",
          value: "code_id",
          sortable: false,
        },
        {
          text: "ຕຸກນ້ຳ",
          align: "start",
          value: "name",
          sortable: false,
        },
        {
          text: "ຈຳນວນຄັ້ງໃນການໃຊ້",
          align: "center",
          value: "round",
        },
        // {
        //   text: "ສະຖານະການເຄື່ອນໄຫວ",
        //   align: "center",
        //   value: "status_type",
        //   sortable: false,
        // },
        {
          text: "Created",
          align: "start",
          value: "created_at",
        },
        {
          text: "Updated",
          align: "start",
          value: "updated_at",
        },
        // { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  metaInfo() {
    return {
      title: `ຂໍ້ມູນຕຸກນ້ຳເປົ່າທັງໝົດ`,
    }
  },
  methods: {
    //Bottle No have Water
    fetchDataEmptyWaterBottle() {
      this.TableLoading = true;
      this.$axios
        .get("factory/list-products/empty", {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            search: this.searchEmptyWater,
          },
        })
        .then((res) => {
          this.emptyWaterBottle = res.data.products.data;
          this.countEmptyBottle = res.data.products.total;
          this.pagination = res.data.products;
          this.TableLoading = false;
        })
        .catch(() => {
          this.TableLoading = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },
    //Get Round color
    getRoundColor(round) {
      if (round >= 20) return "error";
      else return "success";
    },

    getColor(status) {
      if (status == "using") return "success";
      else if (status == "empty") return "info";
      else if (status == "expire") return "error";
      else if (status == "close") return "warning";
      else return;
    },
    getStatus(status) {
      if (status == "using") return "ມີນ້ຳ";
      else if (status == "empty") return "ບໍ່ມີນ້ຳ";
      else if (status == "expire") return "ໝົດອາຍຸ";
      else if (status == "close") return "ປິດໃຊ້ງານ";
      else return;
    },
    getStatusType(status_type) {
      if (status_type == "inbound_factory") return "ຢູ່ໂຮງງານ";
      else if (status_type == "outbound_factory") return "ອອກຈາກໂຮງງານ";
      else if (status_type == "inbound_branch") return "ຢູ່ຂາຂາ";
      else if (status_type == "outbound_branch") return "ອອກຈາກສາຂາ";
      else if (status_type == "customer") return "ຢູ່ນຳລູກຄ້າ";
      else if (status_type == "coming_to_factory") return "ກຳລັງກັບສູ່ໂຮງງານ";
      else return status_type;
    },
    getStatusTypeColor(status) {
      if (status == "inbound_factory") return "warning";
      else if (status == "outbound_factory") return "success";
      else if (status == "inbound_branch") return "info";
      else if (status == "outbound_branch") return "success";
      else if (status == "customer") return "primary";
      else if (status == "coming_to_factory") return "error";
      else return;
    },

    exportData() {
      this.exportLoading = true;
      this.$axios
          .get(
              "export-products/empty", {responseType: "blob"}
          )
          .then((res) => {
            setTimeout(() => {
              const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
              const fileLink = document.createElement("a");
              fileLink.href = fileUrl;
              fileLink.setAttribute(
                  "download",
                  "Bottle_Empty" +
                  ".xlsx"
              );
              document.body.appendChild(fileLink);
              fileLink.click();
              document.body.removeChild(fileLink);
              this.exportLoading = false;
            }, 100);
          })
          .catch(() => {
            this.exportLoading = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
    },
  },
  created() {
    this.fetchDataEmptyWaterBottle();
  },
};
</script>
<style>
.primary-color {
  color: #00c1d2;
}
</style>
